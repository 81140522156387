import logo from "./logo.png";
import "./App.css";

function App() {
  return (
    <div className="app">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
        <p>Schaalbaar alarmeringssysteem voor incidenten.</p>
        <p>
          Mail ons op:{" "}
          <a
            className="link"
            href="mailto:post@pieper.app?subject=Contact%20via%20de%20website"
            target="_blank"
            rel="noopener noreferrer"
          >
            post@pieper.app
          </a>
        </p>
      </header>
      <footer className="footer">
        <p>&#169; Pieper. Kvk 76656292, Kerklaan 4, 1211PP Hilversum</p>
      </footer>
    </div>
  );
}

export default App;
